
import { Component, Mixins, Emit } from "vue-property-decorator";
import Singlepages from "@/mixins/SinglepagesJa";
import PageHeader from "@/components/page/PageHeader.vue";

@Component({
  components: {
    PageHeader,
  },
})
export default class Pickup extends Mixins(Singlepages) {
  private name = "pickup";
  private pageTitle1 = "PICK UP";
  private pageTitle2 = "INTRODUCING THE HOTEL";
  private newentriesLabel = "新着記事";
}
